<template>
    <div class="full-height pa-10-20 overflow-y-auto">
        <div class="justify-space-between items-center">
            <h6>{{ program.name }}</h6>
            <div v-if="user.roleCode == 0">
                <button
                    class="box mr-10 pa-4-10 size-px-12 mr-10 bg-white"
                    @click="getSample"
                >
                    <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                    <span class="vertical-middle">대량 등록 양식</span></button>

                <label class="box mr-10 pa-4-10 size-px-12 bg-white vertical-middle">
                    <input
                        v-show="false"
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        @change="setExcelFile"
                    />
                    <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                    대량 등록
                </label>
            </div>
        </div>

        <div>
            <div class="justify-space-between">
                <div class="flex-1 bg-white pa-10 mr-10">
                    <h6 class="under-line-identify">신청 구분</h6>
                    <table class="table th-left td-left">
                        <col width="140px">
                        <col width="width-50-120">
                        <col width="140px">
                        <col width="width-50-120">
                        <tbody>
                        <tr>
                            <th>소속 영업단 <span class="color-red">*</span></th>
                            <td colspan="3">
                                <div>
									<span
                                        v-if="item.upper_name || upper_name"
                                        class="box pa-5-10 vertical-middle mr-10 bg-eee"
                                    >{{ item.upper_name ? item.upper_name : upper_name }}</span>

                                    <button
                                        class="bg-identify pa-5-10 vertical-middle"
                                        @click="getAgencyList"
                                    >영업단 조회
                                    </button>

                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>아이디 <span class="color-red">*</span></th>
                            <td>
                                <input
                                    v-model="item.id"
                                    class="input-box pa-5-10 mr-10"
                                    placeholder="아이디를 입력하세요"
                                    :readonly="readonlyId"
                                />

                                <button
                                    class="pa-5-10 bg-gray "
                                    :class="{ 'bg-green': is_id_check}"
                                    @click="doIdCheck"
                                >중복 체크
                                </button>
                            </td>
                            <th>가입일 <span class="color-red">*</span></th>
                            <td class="position-relative">
<!--                                <input v-model="item.registerDate" placeholder="가입일" class="input-box" readonly-->
<!--                                       @click="is_data_pick = !is_data_pick"/>-->
                                <input v-model="item.registerDate" placeholder="가입일" class="input-box" readonly
                                       @click="is_data_pick = false"/>
                                <v-date-picker
                                    v-if="is_data_pick"
                                    v-model="item.registerDate"
                                    no-title
                                    scrollable
                                    class="position-absolute"
                                    style="top: 45px; right: 30px"
                                    @change="is_data_pick = false"
                                ></v-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호 <span class="color-red">*</span></th>
                            <td class="">
                                <input
                                    v-model="item.password"
                                    type="password"
                                    class="input-box full-width"
                                    placeholder="비밀번호를 입력하세요"
                                    max="50"
                                />
                            </td>

                            <th>비밀번호 확인 <span class="color-red">*</span></th>
                            <td class="">
                                <input
                                    v-model="item.passwordCk"
                                    type="password"
                                    class="input-box full-width"
                                    placeholder="비밀번호를 한번 더 입력하세요"
                                    max="50"
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>수기결제 MID</th>
                            <td class="">
                                <input
                                    v-model="item.manualPaymentId"
                                    class="input-box full-width"
                                    placeholder="수기결제 MID"
                                    max="50"
                                />
                            </td>

                            <th>수기결제 MID KEY</th>
                            <td class="">
                                <input
                                    v-model="item.manualPaymentPassword"
                                    type="password"
                                    class="input-box full-width"
                                    placeholder="수기결제 MID KEY"
                                    max="50"
                                />
                            </td>
                        </tr>

                        <tr>
                            <th>PG사</th>
                            <td class="">
                                <select
                                    v-model="item.manualPaymentVanType"
                                    class="input-box pa-5-10 "
                                >
                                    <option value="">선택하세요</option>
                                    <template
                                        v-for="(type, index) in codes.manual_type"
                                    >
                                        <option
                                            :key="'type_' + index"
                                            :value="type.code"
                                        >{{ type.name }}
                                        </option>
                                    </template>
                                </select>
                            </td>

                            <th>결제방식 <span class="color-red">*</span></th>
                            <td class="">
                                <select
                                    v-model="item.canNonVerifyManualPayment "
                                    class="input-box pa-5-10 "
                                >
                                    <option value="">선택하세요</option>
                                    <template
                                        v-for="(type, index) in codes.manual_payment_type "
                                    >
                                        <option
                                            :key="'type_' + index"
                                            :value="type.code"
                                        >{{ type.name }}
                                        </option>
                                    </template>
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div class="flex-1 bg-white pa-10">
                    <h6 class="mt-10 under-line-identify">대표자 정보</h6>

                    <table class="table th-left td-left">

                        <col width="140px"/>
                        <col width="width-50-120"/>
                        <col width="140px"/>
                        <col width="width-50-120"/>

                        <tbody>
                        <tr>
                            <th>대표자명 <span class="color-red">*</span></th>
                            <td>
                                <input
                                    v-model="item.name"
                                    class="input-box pa-5-10 mr-10"
                                    placeholder="대표자명을 입력하세요"
                                />
                            </td>
                            <th>휴대폰 <span class="color-red">*</span></th>
                            <td>
                                <input
                                    v-model="item.hp"
                                    class="input-box pa-5-10 mr-10"
                                    placeholder="대표자 휴대폰번호를 입력하세요"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>자택주소 <span class="color-red">*</span></th>
                            <td colspan="3">

                                <input
                                    v-model="item.homeZipCode"

                                    class="input-box-inline mr-10"
                                    type="text" placeholder="우편번호"
                                    readonly

                                    @click="daumPost('home')"
                                >

                                <button
                                    class="box pa-5-10 bg-identify"
                                    @click="daumPost('home')"
                                >주소 검색
                                </button>

                                <input
                                    v-model="item.homeAddress"

                                    class="input-box  mt-10" type="text" placeholder="주소"
                                    readonly

                                    @click="daumPost('home')"
                                >

                                <input
                                    v-model="item.homeAddressDetail"

                                    class="input-box  mt-10" type="text" placeholder="상세주소를 입력하세요."
                                    maxlength="50"
                                >
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="mt-10 justify-space-between">
                <div class="flex-1 mr-10 bg-white">
                    <div class="pa-10">
                        <div
                            class="under-line-identify justify-space-between"
                        >
                            <h6>서비스 이용 조건</h6>
                            <span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
                        </div>

                        <table class="table th-left td-left">
                            <col width="140px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr>
                                <th>서비스 수수료 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-1">
                                            배달비
                                            <input
                                                v-model="item.quickFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('QUICK')"
                                                :rules="[rules.demical(item, 'quickFee', { min: 2, max: 2})]"/> %
                                        </div>
                                        <div class="flex-1">
                                            월세
                                            <input
                                                v-model="item.monthlyFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('MONTHLY')"
                                                :rules="[rules.demical(item, 'monthlyFee', { min: 2, max: 2})]"/> %
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>수기결제 정산 주기 <span class="color-red">*</span></th>
                                <td>
                                    <input type="radio" id="0" :value="false" v-model="isInstant">
                                    <label for="0" class="ml-5 mr-20">
                                        D-Day 정산(0일 이후 정산)
                                    </label>
                                    <input type="radio" id="1" :value="true" v-model="isInstant">
                                    <label for="1" class="ml-5">
                                        즉시 정산
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>수기결제 수수료 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-1" v-if="!isInstant">
                                            영업일 기준
                                            <select
                                                v-model="item.settlementCycle"
                                                class="pa-5-10 "
                                            >
                                                <option value="">선택</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                            </select>
                                            일 뒤
                                            <input
                                                v-model="item.nonInstantFee"
                                                class="input-box-inline width-50px text-right"
                                                @change="checkFee('D_DAY')"
                                                :rules="[rules.demical(item, 'nonInstantFee', { min: 2, max: 2})]"/> %를
                                            제외한 매출 정산
                                        </div>
                                        <div class="flex-1" v-else>
                                            즉시 정산
                                            <input
                                                v-model="item.instantFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('D_0')"
                                                :rules="[rules.demical(item, 'instantFee', { min: 2, max: 2})]"/> %
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>한도 설정 <span class="color-red">*</span></th>
                                <td>
                                    1회 한도
                                    <input
                                        type="text"
                                        v-model="format.formattedOneLimit"
                                        placeholder="입력"
                                        class="input-box-inline text-right"
                                        @input="formatInput('ONE')"
                                    /> 만원

                                    <div class="mt-10 mb-10">
                                        월한도
                                        <input
                                            type="text"
                                            v-model="format.formattedMonthlyLimit"
                                            placeholder="입력"
                                            class="input-box-inline text-right"
                                            @input="formatInput('MONTHLY')"
                                        /> 만원
                                    </div>

                                    연 한도
                                    <input
                                        type="text"
                                        v-model="format.formattedYearlyLimit"
                                        placeholder="입력"
                                        class="input-box-inline text-right"
                                        @input="formatInput('YEARLY')"
                                    /> 만원
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-1 bg-white">

                    <div class="pa-10">

                        <h6 class="under-line-identify">사업자 정보</h6>
                        <table class="table th-left td-left">
                            <col width="140px">
                            <col width="width-50-120">
                            <col width="140px">
                            <col width="width-50-120">
                            <tbody>
                            <tr>
                                <th>사업자 구분 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <label
                                        class="cont-radio"
                                        v-for="code in codes.P02"
                                        :key="code.TOTAL_CODE"
                                    >
                                        <input
                                            v-model="item.businessType"
                                            type="radio"
                                            :value="code.TOTAL_CODE"
                                            class="box"
                                        />
                                        {{ code.code_name }}
                                    </label>
                                </td>
                            </tr>
                            <tr v-if="Number(item.businessType) !== 2">
                                <th>생년월일 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input type="date" v-model="item.birthDate">
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '1'"
                            >
                                <th>사업자등록번호 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        type="text"
                                        v-model="item.businessNumber"
                                        :ruels="[rules.max(item, 'businessNumber', 10)]"
                                        class="input-box-inline"
                                        placeholder="사업자 등록 번호"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '0'"
                            >
                                <th>사업자등록번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.businessNumber"
                                        :ruels="[rules.max(item, 'businessNumber', 10)]"
                                        class="input-box"
                                        placeholder="사업자 등록 번호"
                                    />
                                </td>
                                <th>법인등록번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.corporationNumber"
                                        :ruels="[rules.max(item, 'corporationNumber', 13)]"
                                        class="input-box"
                                        placeholder="법인 등록 번호"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '2'"
                            >
                                <th>주민등록 번호 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        v-model="item.frontSSN"
                                        type="text"
                                        :ruels="[rules.max(item, 'frontSSN', 6)]"
                                        class="input-box-inline mr-10"
                                        placeholder="주민등록 앞번호"
                                    />
                                    <input
                                        v-model="item.backSSN"
                                        type="password"
                                        maxlength="7"
                                        class="input-box-inline"
                                        placeholder="주민등록 뒷번호"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>업태 <span class="color-red">*</span></th>
                                <td class="si-desc"
                                    style="margin-right: 10px;">
                                    <input
                                        type="text"
                                        v-model="item.businessCondition"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="업태를 입력하세요"
                                    />
                                </td>
                                <th>업종 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.industry"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="업종을 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>상호명 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        type="text"
                                        v-model="item.shopName"
                                        maxlength="50"
                                        class="input-box"
                                        placeholder="상호명을 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>유선 전화</th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.linePhone"
                                        :ruels="[rules.max(item, 'linePhone', 12)]"
                                        class="input-box full-width"
                                        placeholder="유선전화번호를 입력하세요"
                                    />
                                </td>
                                <th>팩스 번호</th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.fax"
                                        :ruels="[rules.max(item, 'fax', 12)]"
                                        class="input-box full-width"
                                        placeholder="팩스번호를 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>사업장 주소 <span class="color-red">*</span></th>
                                <td colspan="3">

                                    <input
                                        v-model="item.zipCode"

                                        class="input-box-inline mr-10"
                                        type="text" placeholder="우편번호"
                                        readonly

                                        @click="daumPost('company')"
                                    >

                                    <button
                                        class="box pa-5-10 bg-identify"
                                        @click="daumPost('company')"
                                    >주소 검색
                                    </button>

                                    <input
                                        v-model="item.address"

                                        class="input-box full-width mt-10" type="text" placeholder="주소"
                                        readonly

                                        @click="daumPost('company')"
                                    >
                                    <input
                                        v-model="item.addressDetail"

                                        class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
                                        maxlength="50"
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div class="mt-10 justify-space-between">
                <div class="flex-1 mr-10 bg-white">
                    <div class="pa-10">
                        <h6>정산 계좌 정보</h6>
                        <table class="table th-left td-left top-line-identify">
                            <col width="140px"/>
                            <col width="width-50-120"/>
                            <col width="140px"/>
                            <col width="width-50-120"/>
                            <tbody>
                            <tr>
                                <th>은행 <span class="color-red">*</span></th>
                                <td>
                                    <select
                                        v-model="item.calculateBankIdx"
                                        class="input-box"
                                    >
                                        <option value="">선택하세요</option>
                                        <option
                                            v-for="(code, index) in codes.P13"
                                            :key="'bank_' + index"
                                            :value="code.idx"
                                        >{{ code.name }}
                                        </option>
                                    </select>
                                </td>
                                <th>계좌번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.calculateAccount"
                                        type="number"
                                        class="input-box full-width"
                                        :rules="[rules.max(item, 'calculateAccount', 25)]"
                                        placeholder="정산 계좌 번호"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>예금주 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.calculateAccountHolder"
                                        class="input-box mr-10"
                                        placeholder="정산 계좌 예금주"
                                        maxlength="15"
                                    />
                                </td>
                                <th>이메일 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.email"
                                        maxlength="50"
                                        class="input-box"
                                        placeholder="세금계산서 발행 이메일을 입력하세요"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-1 bg-white">

                    <div class=" pa-10 ">
                        <h6>첨부파일</h6>
                        <table class="table th-left td-left full-width top-line-identify">
                            <col width="140px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr>
                                <th>계약서류 <span class="color-red">*</span></th>
                                <td>
                                    <div
                                        v-for="(file, index) in codes.merchant_files"
                                        :key="'file_' + index"

                                        class=" ptb-10"
                                    >
                                        <label class="box pa-5-10 mr-10 vertical-middle">
                                            <input
                                                v-show="false"
                                                type="file"
                                                accept="image/*"
                                                @change="setFile($event, file.code)"
                                            />
                                            <v-icon
                                                small
                                            >mdi mdi-image-multiple
                                            </v-icon>
                                            {{ file.name }}
                                        </label>

                                        <button
                                            v-if="item[file.code]"
                                            :key="'file_delete_' + file.code"
                                            @click="isDeleteFile(file.code)"
                                            class="btn-default pa-5-10 vertical-middle"
                                        >{{ item[file.code].name }}
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </div>

                                    <div class="mt-10">
                                        <label class="box pa-5-10 vertical-middle">
                                            <input
                                                v-show="false"
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                @change="setFile($event, 'attachment')"
                                            />
                                            <v-icon
                                                small
                                            >mdi mdi-image-multiple
                                            </v-icon>
                                            첨부파일
                                        </label>
                                    </div>

                                    <div class="mt-10">
                                        <button
                                            v-for="(file, index) in item.attachments"
                                            :key="'file_delete_' + index"
                                            @click="isDeleteFile('attachment', index)"
                                            class="btn-default pa-5-10 mr-10 mb-10"
                                        >{{ file.name }}
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="mtb-30 justify-center"
        >
            <button
                class="bg-identify pa-10-20 mr-10"
                @click="save"
            >저장
            </button>
            <button
                class="bg-ddd pa-10-20"
                @click="toList"
            >목록
            </button>
        </div>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            :top="true"
            :bottom="false"

            title="소속 영업단 조회"
            height="850px"

            @close="clear"
        >
            <AgencySearchList
                slot="modal-content"

                :item_type="99"
                :user="user"

                @click="setUpper"
                @cancel="clear"
                @next="nextUpper"
            ></AgencySearchList>
        </Modal>
        <Modal
            :is_modal="is_modal2"
            :option="modal_option2"
            width="380px"

            @click="setNewId"
            @close="clear"
            @cancel="clear"
        >
            <div slot="modal-content">
                이미 사용중인 아이디입니다. <br/>
                확인시 서브 계정 아이디로 생성됩니다.
            </div>
        </Modal>
        <Modal
            :is_modal="is_upload_bulk"
            :option="{}"
            :top="true"
            :bottom="true"

            title="대량 등록"
            width="400px"

            @close="is_upload_bulk = false"
            @click="uploadBulkFile"
            @cancel="is_upload_bulk = false"
        >
            <div
                slot="modal-content"
                class="pa-10 flex-column align-left"
            >
                <div class="text-left">대량 등록 시 ".xlsx" 파일만 업로드가 가능합니다. 업로드 시 수분이 걸릴 수 있으니 창을 닫거나 페이지 이동을 하지 마세요.</div>
                <div class="mt-10">
                    <input type="checkbox" id="chk_bulk" v-model="isApprove">
                    <label class="ml-5" for="chk_bulk">일괄 승인을 하겠습니다.</label>
                </div>
            </div>
        </Modal>
        <DaumPost
            :overlay="is_post"
            :config="daum_config"
            @callBack="addPost"
        ></DaumPost>
    </div>
</template>

<script>
import Modal from "@/components/Modal";
import DaumPost from "@/components/Daum/DaumPost";
import AgencySearchList from "@/view/Agency/AgencySearchList";

export default {
    name: 'MerchantItem',
    components: {AgencySearchList, DaumPost, Modal},
    data: function () {
        return {
            user: [],
            program: {
                name: '가맹점 등록'
                , top: true
                , title: true
                , bottom: false
            }
            , fee_base: 4
            , is_data_pick: false
            , item: {
                branchIdx: ''
                , distributorIdx: ''
                , agencyIdx: ''
                , resellerIdx: ''
                , subresellerIdx: ''
                , upperName: ''
                , registerDate: this.date.getToday('-')
                , settlementCycle: ''
                , attachments: []
                , businessType: 0
                , bankIdx: ''
                , calculateBankIdx: ''
                , frontSSN: ''
                , businessNumber: ''
                , corporationNumber: ''
                , manualPaymentVanType: ''
                , canNonVerifyManualPayment: ''
                , businessCondition: ''
                , industry: ''
                , quickFee: 0
                , monthlyFee: 0
                , nonInstantFee: 0
                , instantFee: 0
                , birthDate: ''
            }
            , isInstant: false
            , readonlyId: false
            , is_id_check: false
            , is_modal: false
            , modal_option: {}
            , is_modal2: false
            , modal_option2: {
                title: '아이디 조회'
                , top: true
                , content: '이미 사용중인 아이디 입니다. 서브 계정아이디로 생성됩니다.'
                , bottom: true
            }
            , daum_type: ''
            , is_post: false
            , daum_config: {
                width: '360px'
            }
            , agency_list: []
            , upperIdx: ''
            , fee2: 0
            , maxAvailableFee: 0
            , feeInfo: []
            , shopFeeType: ['QUICK', 'MONTHLY', 'D_DAY', 'D_0']
            , bulkFile: []
            , isApprove: false
            , is_upload_bulk: false
            , format: {
                formattedOneLimit: 0,
                formattedMonthlyLimit: 0,
                formattedYearlyLimit: 0
            }
        }
    }
    , computed: {

        upper_name: function () {
            let upper_name = ''
            return upper_name
        }
    }
    , methods: {
        formatInput: function(type) {
            let format = 0
            if(type === 'ONE') {
                format = parseFloat(this.format.formattedOneLimit.replace(/[^0-9]/g, ''))
                this.item.oneLimit = format
                this.format.formattedOneLimit = format.toLocaleString("en-US");
                console.log(this.format.formattedOneLimit)
            } else if(type === 'MONTHLY'){
                format = parseFloat(this.format.formattedMonthlyLimit.replace(/,/g, "")) || 0;
                this.item.monthlyLimit = format
                this.format.formattedMonthlyLimit = format.toLocaleString("en-US");
            } else if(type === 'YEARLY'){
                format = parseFloat(this.format.formattedYearlyLimit.replace(/,/g, "")) || 0;
                this.item.yearlyLimit = format
                this.format.formattedYearlyLimit = format.toLocaleString("en-US");
            }

        },
        getSample: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get',
                    url: 'shop/bulk',
                    blob: true,
                    sample: true
                })
                if(result.success) {
                    const url = window.URL.createObjectURL(
                        new Blob([result.data.data], { type: 'application/octet-stream'})
                    );

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '가맹점_등록양식.xlsx')

                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message })
                }
            } catch (e) {
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        },
        setExcelFile: function (e) {
            console.log('asdasdasd')
            if (e.target.files[0]) {
                this.bulkFile = []
                this.bulkFile = e.target.files[0]
            }
            console.log(this.bulkFile)
            this.is_upload_bulk = true
        },
        uploadBulkFile: async function () {
                console.log(this.bulkFile)
                try {
                    this.$layout.onLoading()
                    const result = await this.$Axios({
                        method: 'post'
                        , url: 'shop/bulk'
                        , data: {
                            file: this.bulkFile,
                            isApprove: this.isApprove
                        }
                        , multipart: true
                    })
                    if (result.success) {
                        this.$layout.setNotify( {type: 'success', message: '대량 등록이 완료되었습니다.'})
                    } else {
                        this.$layout.setNotify( {type: 'error', message: result.message})
                    }
                    this.isApprove = false
                    this.is_upload_bulk = false
                } catch (e) {
                    console.log(e)
                } finally {
                    this.$layout.offLoading()
                }
        },
        trans_birthday: function (birth) {
            const code = Number(this.item.backSSN.slice(0, 1))
            let dateOfBirth = ''

            if (code === 1 || code === 2 || code === 5 || code === 6) {
                // 한국인 1900~, 외국인 1900~
                dateOfBirth = "19" + birth;
            } else if (code === 3 || code === 4 || code === 7 || code === 8) {
                // 한국인 2000~, 외국인 2000~
                dateOfBirth = "20" + birth;
            } else if (code === 9 || code === 0) {
                // 한국인 1800~
                dateOfBirth = "18" + birth;
            }
            return dateOfBirth.slice(0, 4) + '-' + dateOfBirth.slice(4, 6) + '-' + dateOfBirth.slice(6);
        }
        , getAgencyList: async function () {
            this.is_modal = true
        }
        , save: async function () {
            for (const [key, value] of Object.entries(this.item)) {
                if (value === 'null' || value === '' || value === null) {
                    delete this.item[key]
                }
                if (typeof value === 'string') {
                    if (value.indexOf('undef') !== -1) {
                        delete this.item[key]
                    }
                }
            }
            if (Number(this.item.businessType) === 2) {
                this.item.birthDate = this.trans_birthday(this.item.frontSSN)
            }
            if (this.isInstant) {
                this.item.settlementCycle = 0
            }
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'post'
                    , url: 'shop/'
                    , data: this.item
                    , multipart: true
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    this.$tabs.refresh(null, true, false)
                    this.$tabs.open('/Merchant/List/', false, true)
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , clear: function () {
            this.is_modal = false
            this.is_modal2 = false
            this.upperIdx = ''
            this.agency_list = []
            this.readonlyId = false
        }
        , setNewId: function () {
            this.readonlyId = true
            this.is_modal2 = false
            this.is_id_check = true
        }
        , toList: function () {
            this.$router.push({name: 'MerchantList'})
        }

        , daumPost: function (type) {
            this.daum_type = type
            this.is_post = true
        }
        , addPost: function (call) {

            if (this.daum_type == 'company') {
                this.$set(this.item, 'zipCode', call.zonecode)
                this.$set(this.item, 'address', call.address)
            } else {
                this.$set(this.item, 'homeZipCode', call.zonecode)
                this.$set(this.item, 'homeAddress', call.address)
            }

            this.is_post = false
        }
        , isDeleteFile: function (type, index) {
            if (type == 'attachment') {
                this.$delete(this.item.attachments, index)
            } else {
                this.$delete(this.item, type)
            }

        }
        , doIdCheck: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'shop/checkId'
                    , data: {
                        checkId: this.item.id
                    }
                })
                if (result.success) {
                    if (result.data) {
                        if (result.data.duplicate) {
                            this.item.id = result.data.newID
                            this.readonlyId = true
                            this.is_modal2 = true
                            this.is_id_check = true
                        } else {
                            this.$layout.setNotify( {type: 'success', message: '사용 가능한 아이디 입니다'})
                            this.is_id_check = true
                        }
                    } else {
                        this.$layout.setNotify( {type: 'error', message: '사용중인 아이디입니다'})
                    }
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }

        , setUpper: function (item, item_list) {
            console.log(item, item_list)
            this.item.branchIdx = item_list.branchList.idx
            this.item.upper_name = item_list.branchList.name
            this.item.distributorIdx = ''
            this.item.agencyIdx = ''
            this.item.resellerIdx = ''
            this.item.subResellerIdx = ''

            if (item.type >= item_list.distributorList.type) {
                this.item.distributorIdx = item_list.distributorList.idx
                this.item.upper_name += ' > ' + item_list.distributorList.name
            }

            if (item.type >= item_list.agencyList.type) {
                this.item.agencyIdx = item_list.agencyList.idx
                this.item.upper_name += ' > ' + item_list.agencyList.name
            }

            if (item.type >= item_list.resellerList.type) {
                this.item.resellerIdx = item_list.resellerList.idx
                this.item.upper_name += ' > ' + item_list.resellerList.name
            }

            if (item.type >= item_list.subResellerList.type) {
                this.item.subresellerIdx = item_list.subResellerList.idx
                this.item.upper_name += ' > ' + item_list.subResellerList.name
            }

            this.clear()
            this.upperIdx = item.idx
        }
        , getFee: async function (type, index) {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'shop/fee'
                    , data: {
                        upperAdminIdx: this.upperIdx
                        , shopFeeType: type
                    }
                })
                if (result.success) {
                    this.feeInfo.push(result.data)
                    this.feeInfo[index].type = type
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , setFile: function (e, type) {
            console.log(type)
            console.log(e.target.files)
            if (type == 'attachment') {
                for (let i = 0; i < e.target.files.length; i++) {
                    this.item.attachments.push(e.target.files[i])
                }
            } else {
                for (let i = 0; i < this.codes.merchant_files.length; i++) {
                    console.log(this.codes.merchant_files[i].code)
                    if (type == this.codes.merchant_files[i].code) {
                        console.log(e.target.files[0])
                        this.$set(this.item, this.codes.merchant_files[i].code, e.target.files[0])
                    }
                }
            }
            console.log(this.item.attachments)
            console.log(this.item)
        }
        , resetUpper: function () {
            this.upperIdx = ''
            this.item.upper_name = ''
        }
        , nextUpper: function (type, sub) {
            this.upperIdx = sub.idx
            this.getAgencyList(type)
        }
        , checkFee: function (type) {
            console.log(this.feeInfo, 'feeInfo')
            if (!this.feeInfo || this.feeInfo.length === 0) {
                this.$layout.setNotify( {
                    type: 'error'
                    , message: `소속 영업단을 선택해 주세요.`
                })
                return
            }
            if (type === 'QUICK') {
                if (this.item.quickFee < this.feeInfo[0].minValue || this.item.quickFee > this.feeInfo[0].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `배달비 수수료는 최소 ${this.feeInfo[0].minValue}%, 최대${this.feeInfo[0].maxValue}% 입력 가능합니다.`
                    })
                    this.item.quickFee = this.feeInfo[0].minValue
                }
            }
            if (type === 'MONTHLY') {
                if (this.item.monthlyFee < this.feeInfo[1].minValue || this.item.monthlyFee > this.feeInfo[1].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `월세 수수료는 최소 ${this.feeInfo[1].minValue}%, 최대${this.feeInfo[1].maxValue}% 입력 가능합니다.`
                    })
                    this.item.monthlyFee = this.feeInfo[1].minValue
                }
            }
            if (type === 'D_DAY') {
                if (this.item.nonInstantFee < this.feeInfo[2].minValue || this.item.nonInstantFee > this.feeInfo[2].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        ,
                        message: `D-DAY 정산 수수료는 최소 ${this.feeInfo[2].minValue}%, 최대${this.feeInfo[2].maxValue}% 입력 가능합니다.`
                    })
                    this.item.nonInstantFee = this.feeInfo[2].minValue
                }
            }
            if (type === 'D_0') {
                if (this.item.instantFee < this.feeInfo[3].minValue || this.item.instantFee > this.feeInfo[3].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        ,
                        message: `즉시 정산 수수료는 최소 ${this.feeInfo[3].minValue}%, 최대${this.feeInfo[3].maxValue}% 입력 가능합니다.`
                    })
                    this.item.instantFee = this.feeInfo[3].minValue
                }
            }
        }
    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)

        console.log(this.item)

    }
    , watch: {
        '$route.params.copyItem': {
            immediate: true
            ,handler: function () {
                if(this.$route.params.copyItem) {
                    this.item = JSON.parse(this.$route.params.copyItem)
                    this.item.registerDate = this.date.getToday('-')
                    console.log(this.item, 'item shop')
                    console.log(this.item.branchName)
                    this.item.upper_name =
                        this.item.branchName
                        + (this.item.distributorName ? ('>' + this.item.distributorName) : '')
                        + (this.item.agencyName ? ('>' + this.item.agencyName) : '')
                        + (this.item.resellerName ? ('>' + this.item.resellerName) : '')
                    setTimeout(() => {
                        this.item.businessNumber = JSON.parse(this.$route.params.copyItem).businessNumber
                        this.item.corporationNumber = JSON.parse(this.$route.params.copyItem).corporationNumber
                        this.item.frontSSN = JSON.parse(this.$route.params.copyItem).frontSSN
                        this.format.formattedOneLimit = this.item.oneLimit.toLocaleString("en-US");
                        this.format.formattedMonthlyLimit = this.item.monthlyLimit.toLocaleString("en-US");
                        this.format.formattedYearlyLimit = this.item.yearlyLimit.toLocaleString("en-US");
                    },100)
                }
            }
        },
        'item.id': {
            handler: function () {
                this.is_id_check = false
            }
        }
        , upperIdx: {
            handler: function (call) {
                if (call) {
                    for (let i = 0; i < this.shopFeeType.length; i++) {
                        this.getFee(this.shopFeeType[i], i)
                    }
                }
            }
        }
        , 'item.businessType': function () {
            console.log(this.item.businessNumber, '00')
            this.item.businessNumber = ''
            this.item.corporationNumber = ''
            this.item.frontSSN = ''
            this.item.backSSN = ''
        }
    }
}
</script>

<style>
.width-fee {
    width: 60px !important;
    text-align: right;
}
</style>