<template>
	<div class="full-height flex-column ">

		<ul
			v-if="Object.keys(items).length > 0"
			class="justify-space-between full-height box overflow-y-auto"
		>
			<template
				v-for="(item, index) in item_list"
			>
				<li
					v-if="(item_type < 99 && item_type > index + 1) || (item_type == 99 && index < 4)"
					:key="'type_' + index"
					class="flex-1 border-right full-height flex-column"
				>
					<div class="bg-eee"><h6>{{ item.typeName }}</h6></div>
					<ul
						v-if="item.list.length > 0"
						class="pa-10 full-height  overflow-y-auto "
					>
						<li
							v-for="(sub, sub_index) in item.list"
							:key="'type_' + index + '_sub_' + sub_index"

							class="box pa-10 radius-10 mt-10  justify-space-between cursor-pointer"
							:class="{'bg-identify-outline' : item_next[item.type] == sub}"

							@click="next(item.type, sub) "
						>
							<div>
							<v-icon
								v-if="false"
								:class="{'color-identify' : item_select.idx == sub.idx}"
							>mdi mdi-checkbox-marked</v-icon>
							{{ sub.name }}
							</div>
							<v-icon
								@click="next(item.type, sub)"
								:class="{'color-identify' : item_next[item.type] == sub}"
							>mdi mdi-chevron-right</v-icon>
						</li>
					</ul>
					<div
						v-else
						class="full-height flex-column justify-center"
					>
						<div class="text-center">
							<v-icon
								class="size-px-48 color-icon"
							>mdi mdi-cloud-off-outline</v-icon>
						</div>
					</div>
				</li>
			</template>
		</ul>

		<div
			v-else
			class="full-height flex-column justify-center"
		>
			<div class="text-center">
				<v-icon
					class="size-px-48 color-icon"
				>mdi mdi-cloud-off-outline</v-icon>
				<br/>
				<br/>
				<div class="font-weight-bold size-px-24">No Data</div>
			</div>
		</div>

		<div class="mt-10">
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>확인</button>
			<button
				class="btn-default pa-10-20"
				@click="$emit('cancel')"
			>취소</button>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'AgencySearchList'
		, props: ['item_type', 'user']
		, components: { }
		, data: function(){
			return {
				item_select: {}
				, item_next: {
					branchList: ''
					, distributorList: ''
					, agencyList: ''
					, resellerList: ''
					, subResellerList: ''
				}
				, items: {
					branchList: []
					, distributorList: []
					, agencyList: []
					, resellerList: []
				}
				,upperIdx: ''
			}
		}
		,computed: {
			item_list: function(){

				let list = []
                let typeName = ''
				for(let [key, value] of Object.entries(this.items)){
                    if(key === 'branchList') {
                        typeName = '총판'
                    } else if(key === 'distributorList') {
                        typeName = '지사'
                    } else if(key === 'agencyList') {
                        typeName = '대리점'
                    } else if(key === 'resellerList') {
                        typeName = '딜러'
                    }
					list.push({
						type: key
                        , typeName: typeName
						, list: value
					})
				}
                console.log(list, 'agency list')
				return list
			}
		}
		,methods: {
			save: function(){
				this.$emit('click', this.item_select, this.item_next)
			}
			,next: function(type, sub){
				this.item_select = sub
				this.item_next[type] = sub
				this.upperIdx = sub.idx

				this.getData(type)
			}
			,getData: async function(type){

				try{
					//this.$layout.onLoading()
					let result = await this.$Axios({
						method: 'get'
						, url: 'common/upper'
						, data: {
							upperIdx: this.upperIdx
						}
					})
					if(result.success){
						this.next_list = result.data
						if(type == 'branchList'){
							this.items.distributorList = this.next_list.distributorList
							this.items.agencyList = this.next_list.agencyList
							this.items.resellerList = this.next_list.resellerList
						}else if(type == 'distributorList') {
							this.items.agencyList = this.next_list.agencyList
							this.items.resellerList = this.next_list.resellerList
						}else if(type == 'agencyList'){
							this.items.resellerList = this.next_list.resellerList
						}else if(type == 'resellerList'){
							//
						}else{
							this.items = this.next_list
						}
					}else{
						this.$layout.setNotify( { type: 'error', message: result.message})
					}
				}catch(e){
					console.log(e)
				}finally {
					//this.$layout.offLoading()
				}
			}
		}
		,created() {
            this.$layout = this.$root.$children[0].$children[0]

			if(this.user.roleCode > 0) {
				this.items.branchList = [{
					idx: this.user.branchIdx
					, name: this.user.branchName
				}]
			}
			if(this.user.roleCode > 1) {
				this.items.distributorList = [{
					idx: this.user.distributorIdx
					, name: this.user.distributorName
				}]
			}
			if(this.user.roleCode > 2) {
				this.items.agencyList = {
					idx: this.user.agencyIdx
					, name: this.user.agencyName
				}
			}


			this.getData(this.user.roleId + 'List')
		}
	}

</script>